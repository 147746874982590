<template>
  <div class="fixed bottom-4 inset-x-0 flex justify-end">
    <transition name="scale-up" appear>
      <button
        class="
          transition
          duration-300
          ease-out
          mx-4
          rounded-full
          flex
          justify-center
          items-center
          w-20
          h-20
          bg-orange
          hover:bg-white
          group
          shadow-lg
          hover:shadow-sm
        "
      >
        <div
          class="
            text-4xl text-white
            font-bold
            group-hover:text-orange
            transition
            duration-300
            ease-out
          "
        >
          <slot />
        </div>
      </button>
    </transition>
  </div>
</template>

<script>
export default {};
</script>