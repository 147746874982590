import axios from "axios";

class GetPromo {
  constructor(api_url, options) {
    this.api = axios.create({
      baseURL: api_url,
    });
    this.options = options;
  }

  async get(code, user_id, product = "") {
    const { data } = await this.api.get(`code/${code}/${user_id}/${product}`);
    return data;
  }

  applyDiscount(promo, value) {
    if (promo.discount_type === "percentage")
      value = value * ((100 - promo.discount) / 100);
    else if (promo.discount_type === "hard_amount")
      value = value - promo.discount;
    return value;
  }

  async use(code, user_id, product) {
    const { data } = await this.api.post("use-code", { code, user_id, product });
    return data;
  }
  
  async update(code, fields) {
    const { data } = await this.api.patch(`code/${code}`, { fields });
    return data;
  }

  async getProductCodes(product) {
    const { data } = await this.api.get(`product-codes/${product}`);
    return data;
  }
}

export default new GetPromo("https://lobster-app-aaoqu.ondigitalocean.app/");
