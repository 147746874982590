<template>
  <div class="relative">
    <FAB @click="toggleModal">+</FAB>
    <modal v-model:active="modal.show" :key="modal.key">
      <h2 class="text-xl font-bold mt-4 mb-2 text-center">
        Create a promocode
      </h2>
      <div class="grid grid-cols-2 gap-4">
        <form-input
          v-model:value="newPromo.discount"
          label="discount"
          type="number"
        />
        <form-input
          v-model:value="newPromo.max_uses"
          label="Maximum uses"
          type="number"
        />
        <div class="text-left">
          <p class="text-lg text-orange">Discount Type</p>
          <card-radio-group
            v-model:value="newPromo.discount_type"
            :radios="disountTypes"
            :preselect="
              disountTypes.map((t) => t.value).indexOf(newPromo.discount_type)
            "
            :wrap="false"
            class="mx-3"
          >
            <template v-slot:default="slotProps">
              <div
                class="
                  text-xl text-center
                  font-bold
                  w-12
                  inline-block
                  whitespace-nowrap
                "
                :class="{
                  'text-orange':
                    newPromo.discount_type == slotProps.radio.value,
                }"
              >
                {{ slotProps.radio.text }}
              </div>
            </template>
          </card-radio-group>
        </div>
        <div class="col-span-2 flex justify-end">
            <Button text="create" type="primary" @click="createPromo" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "../modal.vue";
import FormInput from "../formInput.vue";
import CardRadioGroup from "../CardRadioGroup.vue";
import FAB from "../FAB.vue";
import Button from "../button.vue";
import { tutoruuAPI } from "../../presets";

export default {
  components: {
    Modal,
    FormInput,
    CardRadioGroup,
    FAB,
    Button
},
  data() {
    return {
      modal: {
        key: 0,
        show: false,
      },
      disountTypes: [
        { value: "percentage", text: "%" },
        { value: "hard_amount", text: "EGP" },
      ],
      newPromo: {
        discount: "10",
        discount_type: "percentage",
        max_uses: "5",
      },
    };
  },
  emits: ["create"],
  methods: {
    toggleModal() {
      this.modal.show = !this.modal.show;
      this.modal.key++;
    },
    async createPromo() {
        const data = await tutoruuAPI.post(`tutor/${this.$store.state.user_tutor._id}/create-promo`, { promo: this.newPromo })
        data.times_used = 0;
        data.times_remaining = this.newPromo.max_uses
        this.toggleModal()
        this.$emit("create", data)
    }
  },
};
</script>