<template>
  <div
    class="
      w-full
      mx-auto
      p-6
      shadow
      md:hover:shadow-xl
      rounded-3xl
      bg-white
      transition
      duration-300
      ease-out
      flex flex-col
      space-y-4
    "
    :class="{'opacity-50': promo.is_expired || !promo.active}"
  >
    <div class="flex justify-between items-start">
        <div class="flex flex-col items-start justify-start">
            <h2 class="font-bold text-xl md:text-2xl pr-4 select-all selected:bg-orange selected:text-white mb-1">{{ promo.code }}</h2>
            <h3 class="text-base rounded-lg text-orange font-bold px-2 py-0.5 bg-orange-tint">
                {{ discount }} OFF
              </h3>
        </div>
        <div>
            <Toggle @switch="toggleActive($event)" :active="promo.active" />
        </div>
    </div>
    <div class="w-full h-px bg-gray-400 my-2"></div>
    <div class="flex justify-between">
      <p class="text-sm px-2">
        Used <span class="text-orange font-bold">{{ times_used }}</span> times
      </p>
      <p class="text-sm">
        <span class="text-orange font-bold">{{ times_remaining }}</span> uses left
      </p>
    </div>
    <p class="text-sm self-end font-bold uppercase opacity-40" v-if="!promo.is_expired">
      Expires in {{ expiresIn }}
    </p>
    <p class="text-sm self-end font-bold uppercase opacity-40" v-else>
      Expired
    </p>
  </div>
</template>

<script>
import GetPromo from '../GetPromo';
import { timeTo } from "../helpers";
import Toggle from "./toggle.vue";
export default {
    props: {
        promo: Object,
        times_used: Number,
        times_remaining: Number,
    },
    computed: {
        discount() {
            return this.promo.discount_type === "percentage"
                ? `%${this.promo.discount}`
                : `${this.promo.discount}EGP`;
        },
        expiresIn() {
            return timeTo(this.promo.expiry_date, "minutes");
        },
    },
    methods: {
        async toggleActive(active) {
            this.promo.active = active
            await GetPromo.update(this.promo.code, { active })
        }
    },
    components: { Toggle }
};
</script>